import clsx from 'clsx';
import { Link } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { authStoreContext } from '../../stores/AuthStore';
import { AppButton } from '../core/AppButton';
import logo from '../../images/logo.svg';
import login from '../../images/login.svg';
import loginWhite from '../../images/login-white.svg';
import signup from '../../images/signup.svg';
import './style.scss';

interface Props {}

const Header = (props: Props) => {
	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const { authentication } = useContext(authStoreContext);

	if (authentication) return null;

	function handleCloseMenu() {
		setIsOpenMenu(false);
	}

	return (
		<>
			<header className={clsx('z-50 text-white w-full fixed py-3 bg-main-blue')}>
				<div className="container flex items-center gap-2">
					<div className="text-2xl grow">
						<Link to="/">
							<img className="max-w-60 w-full" src={logo} alt="logo" />
						</Link>
					</div>
					<nav>
						<ul className="hidden lg:flex items-center gap-12 m-0 mr-12 text-base">
							<li>
								<a href="/#about">About</a>
							</li>
							<li>
								<a href="/#steps">Steps</a>
							</li>
							<li>
								<a href="/#price">Price</a>
							</li>
						</ul>
					</nav>
					<Link to="/login" className="mr-4 sm:mr-8 whitespace-nowrap hidden sm:flex items-center gap-2">
						Log in
						<img src={loginWhite} alt="login" />
					</Link>
					<Link to="/register" className="hidden sm:block">
						<AppButton className="!bg-white !text-blue-500 whitespace-nowrap flex items-center gap-2">
							Sign Up
							<img src={signup} alt="signup" />
						</AppButton>
					</Link>
					<button
						onClick={() => setIsOpenMenu((prev) => !prev)}
						className={`header__burger flex sm:hidden ${isOpenMenu ? 'active' : ''}`}
					>
						<span className="header__burger-line line-1"></span>
						<span className="header__burger-line line-2"></span>
						<span className="header__burger-line line-3"></span>
					</button>
				</div>
			</header>
			<nav className={`header__menu flex flex-col items-center ${isOpenMenu ? 'active' : ''}`}>
				<ul className="flex flex-col items-center gap-4 pl-0">
					<li>
						<a href="/#about" onClick={handleCloseMenu}>
							About
						</a>
					</li>
					<li>
						<a href="/#steps" onClick={handleCloseMenu}>
							Steps
						</a>
					</li>
					<li>
						<a href="/#price" onClick={handleCloseMenu}>
							Price
						</a>
					</li>
				</ul>
				<Link
					to="/login"
					className="whitespace-nowrap flex items-center gap-2 mb-4 mt-auto"
					onClick={handleCloseMenu}
				>
					Log in
					<img src={login} alt="login" />
				</Link>
				<Link to="/register" className="whitespace-nowrap flex items-center gap-2" onClick={handleCloseMenu}>
					Sign Up
					<img src={signup} alt="signup" />
				</Link>
			</nav>
		</>
	);
};

export default observer(Header);
