import { Link } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { authStoreContext } from '../../stores/AuthStore';
import './style.scss';

interface Props {}

const Footer = (props: Props) => {
	const { authentication } = useContext(authStoreContext);

	if (authentication) return null;

	return (
		<footer className="text-white w-full bg-main-blue">
			<div className="container">
				<div className="footer__wrapper min-h-fit p-5 flex flex-row flex-wrap gap-6 justify-between items-start">
					<div className="footer__info flex flex-col gap-3">
						<p>
							© {new Date().getFullYear()}{' '}
							<a href=" https://vodoria.net/" target="_blank">
								VODORIA LIMITED
							</a>
						</p>
						<p>Diagorou, 4 KERMIA BUILDING, 5th floor, Office 504, 1097, Nicosia, Cyprus</p>
						<p>All rights reserved.</p>
					</div>
					<nav className="footer__links flex gap-12 text-base">
						<Link to="/contacts">Contacts</Link>
						<Link to="/terms">Terms of service</Link>
						<Link to="/privacy-policy">Privacy Policy</Link>
					</nav>
				</div>
			</div>
		</footer>
	);
};

export default observer(Footer);
