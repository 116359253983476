import {
	BuildOutlined,
	DollarOutlined,
	SettingOutlined,
	SwapOutlined,
	TeamOutlined,
	UserOutlined,
} from '@ant-design/icons';
import React from 'react';
import { Roles } from '../../utils/constants';

const menuItems = [
	{
		name: 'Profile',
		to: '/user/profile',
		roles: [Roles.user],
		icon: <UserOutlined />,
	},
	{
		name: 'Games',
		to: '/user/games',
		roles: [Roles.user],
		icon: <BuildOutlined />,
	},
	{
		name: 'Payments',
		to: '/admin/payments',
		roles: [Roles.admin],
		icon: <DollarOutlined />,
	},
	// {
	// 	name: 'Payments',
	// 	to: '/user/payments',
	// 	roles: [Roles.user],
	// 	icon: <DollarOutlined />,
	// },
	{
		name: 'Requests',
		to: '/admin/requests',
		roles: [Roles.admin],
		icon: <SwapOutlined />,
	},
	{
		name: 'Prices',
		to: '/admin/prices',
		roles: [Roles.admin],
		icon: <DollarOutlined />,
	},
	// {
	// 	name: 'Analytics',
	// 	to: '/admin/analytics',
	// 	roles: [Roles.admin],
	// 	icon: <BarChartOutlined />,
	// },
	{
		name: 'Users',
		to: '/admin/users',
		roles: [Roles.admin],
		icon: <TeamOutlined />,
	},
	{
		name: 'Settings',
		to: '/user/settings',
		roles: [Roles.admin, Roles.user],
		icon: <SettingOutlined />,
	},
];

export default menuItems;
