import React from 'react';
import './src/styles/index.css';
import Layout from './src/components/Layout/Layout';
import { configure } from 'mobx';

configure({
	enforceActions: 'never',
});

export const wrapRootElement = ({ element }) => {
	return <Layout>{element}</Layout>;
};
