import clsx from 'clsx';
import React from 'react';

type Props = {
	children: React.ReactNode;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const AppButton = (props: Props) => {
	return (
		<button
			{...props}
			className={clsx(
				'cursor-pointer disabled:cursor-default bg-blue-500 sm:px-12 py-2 text-white rounded-xl disabled:opacity-40',
				props.className,
			)}
		>
			{props.children}
		</button>
	);
};
