import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';

type Error = {
	message: string;
	id: number;
};

class ErrorsStore {
	errors: Error[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	callError = (message) => {
		const id = Date.now() + Math.random();

		this.errors.push({ message, id });

		setTimeout(() => {
			this.errors = this.errors.filter(({ id: _id }) => _id !== id);
		}, 2000);
	};
}

export const errorsStore = new ErrorsStore();
export const errorsStoreContext = createContext(errorsStore);
