import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import { UsersApi } from '../services/api/Users';
import { Roles } from '../utils/constants';

export type User = {
	id: number;
	role: Roles;
	email: string;
};

export type Profile = {
	freeTipsLeft: number;
	paidTipsLeft: number;
	playedForMonth: number;
	playedForToday: number;
};

class UserStore {
	user: null | User = null;
	profile: null | Profile = null;

	constructor() {
		makeAutoObservable(this);
	}

	get authenticated() {
		return !!this.user;
	}

	fetchProfileInfo = async () => {
		try {
			this.profile = await UsersApi.getProfile();
		} catch (error) {
			console.log('error: ', error);
		}
	};

	setUser = (user: User | null) => {
		this.user = user;
	};
}

export const userStore = new UserStore();
export const userStoreContext = createContext(userStore);
