import { AxiosRequestConfig } from 'axios';
import apiClient from './base';

let { get: _get, post: _post, put: _put, delete: _destroy, patch: _patch } = apiClient;

const get = <T, P = {}>(url) => {
	return (params = {} as P) => {
		return _get<T>(url, { params } as AxiosRequestConfig).then((response) => response.data);
	};
};

const post = <T>(url) => {
	return (body = {}) => {
		return _post<T>(url, body as AxiosRequestConfig['data']).then((response) => response.data);
	};
};

const put = <T>(url) => {
	return (body = {}) => {
		return _put<T>(url, body as AxiosRequestConfig['data']).then((response) => response.data);
	};
};

const patch = <T>(url) => {
	return (body = {}) => {
		return _patch<T>(url, body as AxiosRequestConfig);
	};
};

const destroy = (url) => {
	return (config?: AxiosRequestConfig) => {
		return _destroy(url, config);
	};
};

export { get, post, put, destroy, patch };
