export enum Roles {
	admin = 'admin',
	user = 'user',
}

export enum StartPageByRoles {
	admin = '/admin/payments',
	user = '/user/profile',
}

export const BTCAddress = '3AFaCnqriLNxj15kqtp5Pxn8puHfuqbX7W';

export const TABLE_PER_PAGE = 10;

export const DATE_FORMAT = 'DD.MM.YYYY';
