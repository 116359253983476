import { navigate } from 'gatsby';
import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import { AuthApi } from '../services/api/Auth';
import { UsersApi } from '../services/api/Users';
import { jwtService } from '../services/JWTService';
import { Roles } from '../utils/constants';
import { userStore } from './UserStore';
class AuthStore {
	authentication = false;

	constructor() {
		makeAutoObservable(this);
	}

	auth = () => {
		return new Promise((resolve, reject) => {
			if (this.authentication) return;

			if (!jwtService.getToken()) return reject();

			this.authentication = true;

			UsersApi.getMe()
				.then((response) => {
					userStore.setUser({ role: Roles.admin, ...response });
					resolve(response);
				})
				.catch((err) => {
					jwtService.removeToken();
					reject(err);
				})
				.finally(() => {
					this.authentication = false;
				});
		});
	};

	get authenticated() {
		return !!userStore.user;
	}

	setAuthentication = (flag: boolean) => {
		this.authentication = flag;
	};

	authAndSetToken = async (token) => {
		if (!token) return;
		jwtService.setToken(token);
		return await this.auth();
	};

	signUp = async (email) => await AuthApi.signUp({ email });

	login = async (body) => {
		try {
			const { token } = await AuthApi.login(body);
			const userInfo = await this.authAndSetToken(token);
			return Promise.resolve(userInfo);
		} catch (e) {
			return Promise.reject(e);
		}
	};

	confirmPassword = async (body) => {
		try {
			const { token } = await AuthApi.confirmPassword(body);
			this.authAndSetToken(token);
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	};

	confirmEmail = async (body) => {
		try {
			const { token } = await AuthApi.confirmEmail(body);
			this.authAndSetToken(token);
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	};

	logout = () => {
		jwtService.removeToken();
		userStore.setUser(null);
		navigate('/');
	};
}

export const authStore = new AuthStore();
export const authStoreContext = createContext(authStore);
