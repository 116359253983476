import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';

const KEY = 'ui_collapsed';
class UiSore {
	menuCollapsed = JSON.parse(localStorage.getItem(KEY) || 'false');

	constructor() {
		makeAutoObservable(this);
	}

	setMenuCollapsed = (value: boolean) => {
		this.menuCollapsed = value;
		localStorage.setItem(KEY, value.toString());
	};
}

export const uiSore = new UiSore();
export const uiSoreContext = createContext(uiSore);
