import { Profile } from '../../stores/UserStore';
import { Paginated } from '../../types';
import { get, patch, post } from './methods';

export interface Balance {
	id: number;
	freeAttemptsCount: number;
	paidAttemptsCount: number;
}

export interface IUser {
	id: number;
	email: string;
	isBlocked: boolean;
	createdAt: string;
	balance: Balance;
	lastIp: string
}

export interface ResultDecision {
	decision: ResultGame;
	value?: number;
	profit: number;
}

export const UsersApi = {
	getAllWithoutPagination: get<Paginated<IUser>>('/admin/users-without-pagination'),
	getAll: get<Paginated<IUser>>('/admin/users'),
	getMe: get<IUser>('/user-info'),
	getProfile: get<Profile>('/profile-info'),
	create: post<IUser>('/admin/user'),
	blockUsers: patch('/admin/block'),
	unblockUsers: patch('/admin/unblock'),
};

export interface IUserGame {
	id: number;
	createdAt: string;
	result: number;
}

export enum ResultGame {
	fold = 'fold',
	call = 'call',
	raise = 'raise',
}

export const GamesApi = {
	getAll: get<Paginated<IUserGame>>('/games/history'),
	playGame: post<ResultDecision[]>('/games/play'),
};
