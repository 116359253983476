import axios from 'axios';
import { errorsStore } from '../../stores/ErrorsStore';
import { jwtService } from '../JWTService';

const apiClient = axios.create({
	// baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api' : '/api',
	baseURL: process.env.NODE_ENV === 'development' ? 'https://theta-wave.io/api' : '/api',
});

apiClient.interceptors.request.use(
	(config) => {
		return {
			...config,
			headers: {
				Authorization: jwtService.getToken(),
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		};
	},
	(error) => {
		return Promise.reject(error);
	},
);

apiClient.interceptors.response.use(
	(response) => response?.data,
	(error) => {
		const { description, code } = error.response.data.error || {};

		if (description) {
			errorsStore.callError(`${description} ${code ? '(' + code + ' code)' : ''}`);
		}

		return Promise.reject(error?.response?.data?.error || { description: 'Network error' });
	},
);

export default apiClient;
